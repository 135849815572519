.footer {
    font-size: 14px;
    color: #3d5067;
    padding: 30px 0;
    background: #fafafa;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.main-footer h1 {
    color: #333333;
    font-size: 1.75rem;
    line-height: 1.5;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.main-footer a,
.main-footer p {
    color: #333333;
    display: block;
    margin: 0px;
}

.main-footer a:hover {
    background: transparent;
    text-decoration: none;
    color: #ff0000;
}

.svg-footer {
    width: 35px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 10px;
}

.svg-footer svg {
    width: 20px;
    height: 20px;
}

.items-f {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
}

.items-info {
    font-size: 15px;
    width: calc(100% - 45px);
}

.copyright {
    padding: 14px 0px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    display: flex;
    background: #fafafa;
}

.copyright p {
    font-size: 14px;
    margin: 0px;
}

@media only screen and (max-width: 768px) {
    .main-footer {
        padding-left: 10px;
        padding-right: 10px;
    }
}