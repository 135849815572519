#style1{
  background: url('../../assets/img/bg.jpg') no-repeat;  background-size: cover;  height: calc(100vh);
}
#style2{
  font-size: 240%;font-weight: bold;margin-left: 140px;position: absolute;margin-top: 110px;
}
#style3{
  margin-left: 140px;position: absolute;margin-top: 295px;
}
#style4{
  margin-left: 140px;position: absolute;margin-top: 370px;background: #fb9200;padding: 10px;border: 0;border-radius: 30px;color: #fff;padding-left: 30px;padding-right: 30px;-webkit-box-shadow: 10px 13px 38px -19px rgba(251,146,0,1); -moz-box-shadow: 10px 13px 38px -19px rgba(251,146,0,1); box-shadow: 10px 13px 38px -19px rgba(251,146,0,1);
}
#menu{
  position: fixed;z-index: 999;
}
#style5{
  width: 20px;height: 20px;background: #fb9200;position: absolute;top: 0;right: -5px;border-radius: 50%
}
#order_number{
  position: absolute;margin-top: -30px;margin-left: 7px;color: #fff;font-size: 80%
}
#menu_header22{
  margin: 0 auto;width: 200px; background: orange; border-radius: 8px; font-family: Helvetica, Arial, sans-serif;padding: 0.5rem;position: absolute;z-index: 999;margin-left: calc(100vw - 250px);margin-top: -25px;display: none;
}
#hello_user{
  opacity: 0.9
}
#quanlydon{
  height: 35px;display: none;
}
#style6{
  height: 35px
}
#style7{
  height: 35px
}
#menu_account_login{
  display: none;
}
#style8{
  height: 35px
}
#style9{
  width: 150%;left: -200px;
}
#style10{
  height:520px;overflow-y: scroll;padding-top: 5px;padding-right: 10px;
}
#style11{
  width: 20%;font-size: 115%;
}
#style12{
  width: 44%;font-size: 115%;
}
#style13{
  width: 18%;font-size: 115%;
}
#style14{
  width: 18%;font-size: 115%;
}
#style15{
  font-size: 110%
}
#style16{
  font-size: 115%
}
#style17{
  font-size: 85%; 
  min-height:35px;
}
.clearboth{
  clear: both;
}
.width80{
  width: 80%;
}
.width100{
  width: 100%;
}
.height100{
  height: 100%;
}